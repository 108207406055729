.MusicSection {
    height: auto;
    padding-bottom: 725px;
}

.MusicHeader {
    padding: 50px 0px;
    font-size: 5rem;
    font-family: 'Poppins', sans-serif;
    overflow: auto;
  }

.containerfloat {
    text-align: center;
    width: 80%;
    overflow: auto;
    margin: auto;
    padding: 10px;
    
  }

  .video {
    padding:10px;
    margin-left: 2%;
    background: linear-gradient(var(--colour-primary-variant), transparent);
    webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    width:914px;
    
    overflow:hidden;
    float: left;
}

.ParagraphMusic {
    padding: 5rem 10px;
    margin-left: 52%;
    margin-right: 2%;
    overflow: auto;
    border-radius: 2rem 2rem 2rem 2rem;
    background: var(--colour-primary-variant);
  }

  .ParagraphMusicFull {
    margin-top: 15%;
    position: absolute;
    padding: 2rem 10px;
    margin-left: 2%;
    margin-right: 2%;
    overflow: auto;
    border-radius: 2rem 2rem 2rem 2rem;
    background: var(--colour-primary-variant);
    padding-bottom: 50px;
  }

  /* ====================== MEDIA QUERIES ============== */

@media screen and (max-width: 1381px) and (min-width: 610px) {
    .video {
        margin: auto;
        float: none;
        position: relative;
    }

    .ParagraphMusic {
        margin-left: 5%;
        position: relative;
      }
  }
  
  @media screen and (max-width: 610px){
  
    .ParagraphMusic {
      margin-left: 5%;
      position: relative;
    }

    .video {
      aspect-ratio: 16 / 9;
      width: 100%;
      height: 100%;
    }
  }
