@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.projectsContainer {
    border-top: 5px ridge var(--colour-bg-variant-2);
    padding-top: 225px;
    padding-bottom: 225px;
    background-color: var(--colour-bg-variant);
}

.projectsHeader {
    font-size: 5rem;
    font-family: 'Poppins', sans-serif;
}

.cards {
    padding-top: 125px;
}

