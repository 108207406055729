.InfoHeader {
    height: fit-content;
}

.InfoHeader a:hover,
.loInfoHeadergo a:focus {
    color: rgb(141, 141, 141);
}

.InfoHeader a {
    color: white;
}