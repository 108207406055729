@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.aboutContainer {
  height: auto;
  background-color: var(--colour-bg-variant);
  padding-top: 50px;
  padding-bottom: 225px;
}

.containerfloat {
    text-align: center;
    width: 80%;
    overflow: auto;
    margin: auto;
    padding: 10px;
    
  }
  
  .Header {
    padding: 50px 0px;
    font-size: 5rem;
    font-family: 'Poppins', sans-serif;
    margin-left: 15%;
    width: 30%;
    overflow: auto;
    float: left;
  }
  
  .Paragraph {
    padding: 5rem 10px;
    margin-left: 48%;
    margin-right: 5%;
    overflow: auto;
    border-radius: 2rem 2rem 2rem 2rem;
    background: var(--colour-primary);
  }

/* ====================== MEDIA QUERIES ============== */

@media screen and (max-width: 1200px) and (min-width: 610px) {
  .Header {
    font-size: 2rem;
  }
}

@media screen and (max-width: 610px){
  .Header {
    margin: auto;
    float: none;
    position: relative;
    width: 100%;
  }

  .Paragraph {
    margin-left: 5%;
    position: relative;
  }
}

/*
.containerFloat {
    text-align: center;
    padding: 20px;
}


.text {
    width: 50%;
    float: left;
    padding: 20px;
    border-radius: 2rem 2rem 2rem 2rem;
    background: linear-gradient(var(--colour-bg-variant), transparent);

}

.Header {
    font-size: 5rem;
    font-family: 'Poppins', sans-serif;

}

.Paragraph {
    text-align: center;
    
}

*/