@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;500;800&display=swap');

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --colour-bg: #0d4b5a;
  --colour-bg-variant: #08333d;
  --colour-bg-variant-2: #0a768f;
  --colour-primary: rgba(108, 153, 236, 0.1);
  --colour-primary-variant: rgba(77, 181, 255, 0.4);
  --colour-white: #fff;
  --colour-light: rgba(255, 255, 255, 0.6);
  --transition: all 400ms ease;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-ms: 90%;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: 'Roboto', sans-serif;
  background: var(--colour-bg);
  color: var(--colour-white);
  line-height: 1.7;
  /*background-image: url(../src/assets/background2.jpg);*/
}

/* #################### GENERAL STYLES #################### */
.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1, h2, h3, h4, h5 {
  font-weight: 500;
}

h1 {
  font-size: 2.5rem;
}

section {
  margin-top: 8rem;
}

section > h2, section > h5 {
  text-align: center;
  color: var(--colour-light);
}

section > h2 {
  color: var(--colour-primary-variant);
}

.text-light {
  color: var(--colour-light);
}

a {
  color: var(--colour-primary-variant);
  transition: var(--transition);
}

a:hover {
  color: var(--colour-white);
}

.btn {
 width: max-content;
 display: inline-block;
 color: var(--colour-primary-variant);
 padding: 0.75rem 1.2rem;
 border-radius: 0.4rem;
 cursor: pointer;
 border: 1px solid var(--colour-primary-variant);
 transition: var(--transition);
}

.btn:hover {
  background: var(--colour-white);
  color: var(--colour-bg);
  border-color: transparent;
}

.btn-primary {
  background: var(--colour-primary-variant);
  color: var(--colour-bg);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

/* ====================== MEDIA QUERIES ============== */
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    margin-top: 6rem;
  }
}

@media screen and (max-width: 600px){
  .container {
    width: var(--container-width-sm);
  }

  section {
    margin-top: 2rem;
  }
}

