@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

header {
    height: 100vh;
    padding-top: 10rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 4rem;
}

h5 {
    font-family: 'roboto', sans-serif;
    animation: fadeIn linear 3s;
    animation-delay: 2s;
  -webkit-animation: fadeIn linear 3s;
  -moz-animation: fadeIn linear 3s;
  -o-animation: fadeIn linear 3s;
  -ms-animation: fadeIn linear 3s;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

.headerSocials {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    position: absolute;
    margin-top: 4rem;
    left: calc(30% - 11rem);
    
}

.headerSocials::after {
    content: '';
    width: 0px;
    height: 2rem;
    background: var(--colour-primary-variant);
}

.profileAvitar {
    background: linear-gradient(var(--colour-primary-variant), transparent);
    width: 25rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0rem 0rem;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}

/* ====================== MEDIA QUERIES ============== */
@media screen and (min-width: 1354px){
    .wrapper {
        height: fit-content;
        display: grid;
        place-items: center;
    }
    
    .typingAnimation {
        width: 32ch;
        animation: typing 2s steps(22), blink .5s step-end infinite alternate;
        white-space: nowrap;
        overflow: hidden;
        border-right: 3px solid;
        font-family: monospace;
      }
      
      @keyframes typing {
        from {
          width: 0
        }
      }
          
      @keyframes blink {
        50% {
          border-color: transparent
        }
    }
}

@media screen and (max-width: 1024px) {
    header {
        height: 120vh;
    }
  }
  
  @media screen and (max-width: 600px){
    header {
        height: 135vh;
    }

    .headerSocials {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 0.8rem;
        position: absolute;
        margin-top: 35rem;
        left: calc(30% - 2rem);
    }
  }

