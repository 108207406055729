@import url('https://fonts.googleapis.com/css?family=Work+Sans:300,600');

:root {
    --background: rgba(45, 141, 250, 0.85);
    --clr-neutral-900: hsl(207, 19%, 9%);
    --clr-neutral-100: hsl(0, 0%, 100%);
    --clr-accent-400: hsl(142, 90%, 61%);
}

*, *::before, *::after {
  box-sizing: border-box;
}

.bodyInfo {
	margin: 0;
	background: var(--colour-bg);
	font-family: 'Work Sans', sans-serif;
	font-weight: 400;
    padding-bottom: 10em;
    
}

.text a:hover, .text a:visited, .text a:link, .text a:active
{
    color: rgb(199, 199, 199);
    /*text-decoration: none;*/
}


.paragraphInfoWhite {
    color: white;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    margin-top: 0px;
    margin-bottom: 5px;

    line-height: 1.4;

    font-size: 1.2em;

    text-align: center;
}

.videoInfo {
    padding:10px;
    background:linear-gradient(var(--colour-primary-variant), var(--background));;
    webkit-border-radius: 20px;
    -moz-border-radius: 20px
    ;border-radius: 20px;
    width:914px;
    margin:0 auto;
    overflow:hidden;
}

.image {
    padding-top: 50px;
}

.image img{
    padding:10px;
    background:linear-gradient(var(--colour-primary-variant), var(--background));
    webkit-border-radius: 20px;
    -moz-border-radius: 20px
    ;border-radius: 20px;
    width: 750px;
    margin:0 auto;
    overflow:hidden;
    display: flex;
}
/* cards */
.containerInfo {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.card {
    background: white;
    width: 270px;
    margin: 10px;
    border-radius: 15px;
}

.card-image {
    background-color: aqua;
    height: 170px;
    margin-bottom: 15px;
    background-size: cover;
    border-radius: 15px 15px 0 0;
}

.card h3 {
    padding: 10px;
    font-family: 'Work Sans', sans-serif;
    font-weight: 400;
    font-weight: bold;
    color: black;
    text-align: left;
}

.card p {
    padding: 10px;
    font-family: 'Work Sans', sans-serif;
    font-weight: normal;
    color: black;
    text-align: left;
}

.card a {
    background-color: var(--background);
    color: white;
    padding: 15px 20px;
    display: block;
    text-align: center;
    margin: 20px 50px;
    text-decoration: none;
}
.card a:hover,
.card a:focus {
    background-color: var(--clr-neutral-100);
    color: black;
}/*
.card:hover {
    background-color:  rgb(62, 62, 62);
    color: white;
    cursor: pointer;
    transform: scale(1.03);
    transition: all 150ms ease;
}*/


.card1 {
    background-image:  url('../Images/Cube.png');
}

.card2 {
    background-image: url('../Images/Platfomer.png');
}

.card3 {
    background-image: url('../Images/Switch Jump.png');
}

.card4 {
    background-image:url('../Images/Java IDE.png') ;
}

.card5 {
    background-image: url('../Images/padlock.jpg');
}

.card6 {
    background-image: url('../Images/Logo.png');
}

.card7 {
    background-image: url('../Images/OneWord.png');
}
.card8
{
    background-image: url('../Images/headphones.png');
}

.card9
{
    background-image: url('../Images/Earth.png');
}
.card10
{
    background-image: url('../Images/UniProject.jpg');
}
/* nav styles */



@-webkit-keyframes fade {
    0%   { opacity: 0; }
    100% { opacity: 1; }
  }
  @-moz-keyframes fade {
    0%   { opacity: 0; }
    100% { opacity: 1; }
  }
  @-o-keyframes fade {
    0%   { opacity: 0; }
    100% { opacity: 1; }
  }
  @keyframes fade {
    0%   { opacity: 0; }
    100% { opacity: 1; }
  }
  
  .page2 {
    animation-name: fade;
    animation-duration: 1s; 
    animation-fill-mode: forwards; 
   }



   /* ====================== MEDIA QUERIES ============== */
@media screen and (max-width: 800px){
    .image img{width: 500px;}
}

@media screen and (max-width: 500px){
    .image img{width: 400px;}
}